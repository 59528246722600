/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 20px;
  top: 36px;
}
@media (min-width: 1024px) {
  .bm-burger-button {
    display: none !important;
  }
}
@media (max-width: 640px) {
  .bm-burger-button {
    position: fixed;
    width: 25px;
    height: 20px;
    right: 20px;
    top: 27px;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #e7345b;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #e7345b;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}
@media (min-width: 640px) and (max-width: 1024px) {
  .bm-cross-button {
    right: -70px !important;
    top: 3px !important;;
  }
}

/* Color/shape of close button cross */
.bm-cross {
  background: #000000;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  z-index: 9999999 !important;
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #e7345b;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

.bm-item .btn--inside {
  background-color: #e7345b;
  color: white;
  border-radius: 21px;
  height: 43px;
  align-items: center;
  width: 150px;
  justify-content: center;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}

/* Individual item */
.bm-item {
  display: inline-block;
  /* font-family: 'Nunito'; */
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  color: #191919;
  margin-bottom: 5px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #000000;
}
.flex--socials {
  display: flex !important;
  margin-top: 30px;
  margin-bottom: 30px;
}

.mobile--btn {
  background: #e7345b !important;
  border-radius: 21.5px;
}

.policy--styles h1 {
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: bold;
}
.policy--styles h2 {
  font-size: 26px;
  margin-bottom: 20px;
  font-weight: bold;
}
.policy--styles h3 {
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: bold;
}
.policy--styles h4 {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
}

@media (max-width: 767px) {
  .policy--styles p {
    font-size: 16px !important;
  }
  .policy--styles h1 {
    text-align: center;
  }
  .policy--styles h2 {
    text-align: center;
  }
  .policy--styles h3 {
    text-align: center;
  }
  .policy--styles h4 {
    text-align: center;
  }
}

.delivery--tooltip {
}

.tooltip-arrow {
  display: none;
}

@media (min-width: 570px) {
  .delivery--tooltip {
    top: 0;
    right: 0%;
  }
  .tooltip-arrow {
    display: block;
  }
}
