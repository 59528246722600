.custom--bg {
    background: linear-gradient(#4b4d53 0%, #272a31 100%);;
}

.slick-prev:before, .slick-next:before {
    display: none !important;
}


.border1 {
    border-radius: 16px 0px 0px 0px;
    background: transparent;
    border: 1px solid #fff;
}
@media (max-width: 767px) {
    .border1 {
        border: 1px solid #ffffff;
border-radius: 16px 16px 0px 0px;
    }
}
.border2 {
    background: transparent;
border: 1px solid #fff;
}
@media (max-width: 767px) {
    .border2 {
        border: 1px solid #ffffff;
border-radius: 0px;
    }
}
.border3 {
    border-radius: 0px 16px 0px 0px;
background: transparent;
border: 1px solid #fff;
}
@media (max-width: 767px) {
    .border3 {
        border: 1px solid #ffffff;
border-radius: 0px;
    }
}
.border4 {
        border-radius: 0px 0px 0px 16px;
background: transparent;
border: 1px solid #fff;
}

@media (max-width: 767px) {
    .border4 {
        border-radius: 0px 0px 16px 16px;
background: transparent;
border: 1px solid #fff;
}
}

.border5 {

background: transparent;
border: 1px solid #fff;
}

@media (max-width: 767px) {
    .border5 {
        border: 1px solid #ffffff;
border-radius: 0px;
    }
}
.border6 {
    border-radius: 0px 0px 16px 0px;
background: transparent;
border: 1px solid #fff;
}
@media (max-width: 767px) {
    .border6 {
        border: 1px solid #ffffff;
border-radius: 0px;
    }
}


.process--arrow--right {
    position: absolute;
    width: 47px;
    height: 47px;
    background: #e7345b;
    border-radius: 50%;
    padding: 0.7rem;
    right: -8%;
    bottom: 5%;
    z-index: 999;
}

@media (max-width: 1024px) {
    .process--arrow--right {
        width: 30px;
    height:  30px;
    padding: 0.4rem;
    right: -7%;
    }
    
}

@media (max-width: 767px) {
    .process--arrow--right {
        right: 3%;
    bottom: -8%;
    transform: rotateZ(90deg);
    }
    
}

.process--arrow--left {
    position: absolute;
    width: 47px;
    height: 47px;
    background: #e7345b;
    border-radius: 50%;
    padding: 0.7rem;
    left: -7%;
    bottom: 5%;
    z-index: 999;
    transform: rotateY(180deg);
}

@media (max-width: 1024px) {
    
    .process--arrow--left {
        width: 30px;
    height:  30px;
    padding: 0.4rem;
    left: -5%;
    }
}

@media (max-width: 767px) {
    
    .process--arrow--left {
        bottom: -8%;
    left: 88%;
    transform: rotateZ(90deg);
    }
}


.process--arrow--down {
    position: absolute;
    width: 47px;
    height: 47px;
    background: #e7345b;
    border-radius: 50%;
    padding: 0.7rem;
    right: 5%;
    bottom: -7.4%;
    z-index: 999;
    transform: rotateZ(90deg);

}

@media (max-width: 1024px) {
    
    .process--arrow--down {
        width: 30px;
    height:  30px;
    padding: 0.4rem;
    bottom: -5%;
    }
}

@media (max-width: 767px) {
    .process--arrow--down {
        bottom: -7%;
        right: 3%;
    }
    
}

.process--guy {
    bottom: -30%;
    right: 5%;
}