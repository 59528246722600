input::placeholder {
  font-family: "Nunito";
  font-weight: 300;
  font-style: italic;
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  color: #9699a1;
}

input {
  background: transparent;
  border-bottom: solid white 1px;
  margin-top: 2px;
  padding-bottom: 3px;
  color: white;
}

textarea {
  background: transparent;
  border-bottom: solid white 1px;
  margin-top: 2px;
  padding-bottom: 3px;
  height: 120px;
  color: white;
}

.first--input {
  width: 45%;
}

@keyframes pull {
  0% {
    height: 0;
  }
  100% {
    height: 1.2rem;
  }
}
@keyframes pop {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
  }
}
.checkbox-flip,
.checkbox-pop,
.checkbox-pull,
.checkbox-spin {
  display: none;
}

.checkbox-flip + label span {
  display: inline-block;
  width: 25px;
  height: 19px;
  margin: 0px 21px -4px 0;
  /*layout relationship between check and label*/
}
.checkbox-flip + label span:before,
.checkbox-flip + label span:after {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  background: transparent;
  border: 1px solid #ffffff;
}
.checkbox-flip + label span:after {
  z-index: 0;
  border: none;
}

.checkbox-flip:checked + label span:before {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background: rgb(231, 52, 91);
}

.successForm {
  border-radius: 8px;
  background: #c6dcab;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #3b5818;
  border: 2px solid #8ebc55;
  padding: 14px;
}

.baner .slick-slider {
  max-width: 1350px;
  margin: 0 auto;
}

@media (max-width: 1400px) {
  .baner .slick-slider {
    max-width: 92%;
  }
}

@media (max-width: 600px) {
  .baner .slick-slider {
    max-width: 95%;
  }
}
