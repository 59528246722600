.product--content p {
  font-weight: normal;
  font-size: 18px;
  color: #000;
  margin-bottom: 15px;
}

.product--content li {
  font-weight: normal;
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
}

.product--content ul {
  list-style: disc;
  padding-left: 18px;
}

.product--content h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  font-size: 22px;
  color: #000;
  margin-bottom: 15px;
}

.wp-block-gallery {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 40px;
}

.wp-block-image {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.review--box {
  background: white;
  border-radius: 18px;
}

.review--box:nth-child(odd) {
  background: #f2f2f2;
}

.single-product-slider img {
  width: 100%;
  max-width: 430px;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 207px;
  height: 3px;
  content: "";
  background: black;
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 197px !important;
  height: 3px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

@media (max-width: 1535px) {
  .slick-dots li {
    width: 100px !important;
  }
  .slick-dots li button:before {
    width: 110px;
  }
}

@media (max-width: 1024px) {
  .slick-dots {
    width: 90%;
  }
  .slick-dots li {
    width: 10% !important;
  }
  .slick-dots li button:before {
    width: 115%;
  }
}
