.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 1350px;
  width: 100%;
  height: auto;
  border-radius: 10px;
}
@media (max-width: 1324px) {
  .image {
    max-width: 95%;
  }
}

.right-arrow {
  position: absolute;
  top: 50%;
  transform: translate(4.5%, -50%);
  font-size: 3rem;
  right: 4.5%;
  color: #000;
  z-index: 10;
  cursor: pointer;
  background: #e7345b;
  padding: 1rem;
  max-width: 47px;
  height: 100%;
  border-radius: 50%;
  max-height: 47px;
  width: 100%;
  user-select: none;
}
@media (max-width: 1535px) {
  .right-arrow {
    right: -1.5%;
  }
}

.left-arrow {
  position: absolute;
  top: 50%;
  transform: translate(4.6%, -50%);
  left: 4.6%;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  background: #e7345b;
  padding: 1rem;
  max-width: 47px;
  height: 100%;
  border-radius: 50%;
  max-height: 47px;
  width: 100%;
}
@media (max-width: 1535px) {
  .left-arrow {
    left: -1.5%;
  }
}
.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1);
}

@media (max-width: 1324px) {
  .slide.active {
    display: flex;
    justify-content: center;
  }
  .right-arrow {
    right: 0.5%;
    padding: 0.6rem;
    max-width: 35px;
    max-height: 35px;
  }
  .left-arrow {
    left: 0.5%;
    padding: 0.6rem;
    max-width: 35px;
    max-height: 35px;
  }
}

.slick-dots {
  bottom: -70px !important;
}

.first--word::first-word {
  font-weight: bold;
}

.left-arrowProduct {
  position: absolute;
  top: 50%;
  transform: translate(0.6%, -50%);
  left: 0.6%;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  background: #000000;
  padding: 10px;
  max-width: 37px !important;
  height: 100%;
  border-radius: 0;
  max-height: 37px;
  width: 100%;
}
@media (max-width: 1535px) {
  .left-arrowProduct {
    left: 1.5%;
  }
}

.right-arrowProduct {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  right: 0%;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  background: #000000;
  padding: 10px;
  max-width: 37px !important;
  height: 100%;
  border-radius: 0;
  max-height: 37px;
  width: 100%;
  user-select: none;
}
@media (max-width: 1535px) {
  .right-arrowProduct {
    right: -1.5%;
  }
}

.slick-track {
  display: flex !important;
  align-items: center !important;
}

@media (max-width: 650px) {
  .footer--slider--img img {
    max-width: 100px;
    margin: 0 auto;
  }
}


@media (max-width: 1650px) {
  .footer--slider--img img {
    max-width: 150px;
    margin: 0 auto;
  }
}